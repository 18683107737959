import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

import { Trans } from '../i18n'

function TwoColumnHeadlineText({
  headingLeft,
  headingRight,
  contentLeft,
  contentRight
}) {
  return (
    <>
      <GridWrapper>
        <GridItemWrapper>
          {headingLeft && <Headline>{headingLeft}</Headline>}
        </GridItemWrapper>
        <GridItemWrapper>
          {headingRight && <Headline>{headingRight}</Headline>}
        </GridItemWrapper>
        <GridItemWrapper>
          <Text>
            <Trans id={contentLeft} />
          </Text>
        </GridItemWrapper>
        <GridItemWrapper>
          <Text>
            <Trans id={contentRight} />
          </Text>
        </GridItemWrapper>
      </GridWrapper>
      <MobileWrapper>
        <Headline>{headingLeft}</Headline>
        <Text>
          <Trans id={contentLeft} />
        </Text>
        <Headline>{headingRight}</Headline>
        <Text>
          <Trans id={contentRight} />
        </Text>
      </MobileWrapper>
    </>
  )
}

const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 2rem 0;
  width: 100%;
  gap: 0 2rem;

  @media (max-width: 41rem) {
    display: none;
  }
`

const GridItemWrapper = styled.div`
  font-size: 1.125rem;
  line-height: 1.7rem;
  margin: 0.5rem;
  max-width: 36rem;

  @media (max-width: 1300px) {
    max-width: calc(50vw - 4rem);
    margin: 0;
  }
`

const Spacer = styled.div``

const MobileWrapper = styled.div`
  display: none;

  @media (max-width: 41rem) {
    display: flex;
    flex-direction: column;
    font-size: 1.125rem;
    line-height: 1.7rem;
    padding: 2rem 0;
    margin: 1rem;
    max-width: 36rem;
  }
`

const Headline = styled.h2`
  font-size: 1.5rem;
  font-weight: 900;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: flex-end;
  height: 100%;
`

const Text = styled.div`
  font-size: 1rem;

  @media (max-width: 41rem) {
    margin-bottom: 2rem;
  }
`

TwoColumnHeadlineText.propTypes = {
  headingLeft: PropTypes.string,
  headingRight: PropTypes.string,
  contentLeft: PropTypes.string.isRequired,
  contentRight: PropTypes.string.isRequired
}

export default TwoColumnHeadlineText
