import React from 'react'
import { Helmet } from 'react-helmet'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import I18n, { Trans } from '../i18n'

import image from '../sections/assets/partner-data.jpg'

import Button from '../components/Button'
import Collapsible from '../components/Collapsible'
import ContactStatic from '../components/ContactStatic'
import Headline from '../components/Headline'
import PartnerHeadImage from '../sections/PartnerHeadImage'
import Section from '../components/Section'
import Spacer from '../components/Spacer'

import Contact from '../sections/Contact'
import DataSection from '../sections/DataSection'
import TwoColumnHeadlineText from '../sections/TwoColumnHeadlineText'
import PartnerLogos from '../sections/PartnerLogos'
import PartnerSolutionSection from '../sections/PartnerSolutionSection'

function Partner({ cityCount, locationCount }) {
  return (
    <div>
      <Helmet>
        <title>{I18n.t('partner_page.title')}</title>
      </Helmet>
      <ContactStatic />
      <PartnerHeadImage />

      <HeadlineWrapper>
        <CustomHeadline>{I18n.t('partner_page.headline')}</CustomHeadline>
      </HeadlineWrapper>

      <TwoColumnHeadlineText
        headingLeft={I18n.t('partner_page.heading.exact')}
        headingRight={I18n.t('partner_page.heading.morevalue')}
        contentLeft={'partner_page.content.exact'}
        contentRight={'partner_page.content.morevalue'}
      />

      <ButtonWrapper>
        <Button.Link href="#contact">
          {I18n.t('partner_page.button.text')}
        </Button.Link>
      </ButtonWrapper>

      <CollapsibleSection column mid>
        <Collapsible
          title={I18n.t('partner_page.collapsible.heading_city')}
          open={true}
        >
          <Trans
            id="partner_page.collapsible.content_city"
            values={{ cityCount, locationCount }}
          />
        </Collapsible>
        <Collapsible
          title={I18n.t('partner_page.collapsible.heading_seller')}
          open={false}
        >
          <Trans id="partner_page.collapsible.content_seller" />
        </Collapsible>
        <Collapsible
          title={I18n.t('partner_page.collapsible.heading_owner')}
          open={false}
        >
          <Trans id="partner_page.collapsible.content_owner" />
        </Collapsible>
        <Section.Skew mid direction="up" position="top" />
      </CollapsibleSection>

      <DataSection image={image} headline={"partner_page.data.heading"} content={"partner_page.data.content"} />

      <Section column mid>
        <PartnerSolutionSection />
      </Section>

      <a id="contact"></a>
      <Contact
        teaser="contact.teaser_partner"
        cta="contact.cta_partner"
        service="contact.service"
      />

      <PartnerLogos />
      <Spacer light />
    </div>
  )
}

const CollapsibleSection = styled(Section)`
  padding-top: 2rem;
  padding-bottom: 2rem;
`

const CustomHeadline = styled(Headline)`
  font-size: 2.5rem !important;
`

const HeadlineWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 2rem;
`
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 2rem;
  margin-bottom: 8rem;
`
Partner.propTypes = {
  cityCount: PropTypes.number.isRequired,
  locationCount: PropTypes.number.isRequired
}

export default Partner
