import React from 'react'
import { Helmet } from 'react-helmet'
import styled from '@emotion/styled'

import I18n from '../i18n'

import backgroundImage from './assets/retail-expansion-hero.webp'
import dataImage from './assets/retail-expansion-data.webp'

import Button from '../components/Button'
import Section from '../components/Section'
import Headline from '../components/Headline'
import Spacer from '../components/Spacer'

import DataSection from '../sections/DataSection'
import TwoColumnHeadlineText from '../sections/TwoColumnHeadlineText'
import TripleInfoBox from '../sections/TripleInfoBox'
import Contact from '../sections/Contact'
import WhitePaperContact from '../sections/WhitePaperContact'
import Testimonials from '../sections/Testimonials'

function LandingRetailExpansion() {
  return (
    <div>
      <Helmet>
        <title>{I18n.t('pages.landing.retail_expansion.title')}</title>
      </Helmet>

      <Top light>
        <Section.Skew inset position="bottom" light />
      </Top>

      <Section.Wrapper>
        <HeadlineWrapper>
          <CustomHeadline>
            {I18n.t('pages.landing.retail_expansion.headline')}
          </CustomHeadline>
        </HeadlineWrapper>
        <TwoColumnHeadlineText
          headingLeft={I18n.t(
            'pages.landing.retail_expansion.two_column.headline_left'
          )}
          contentLeft={'pages.landing.retail_expansion.two_column.content_left'}
          contentRight={
            'pages.landing.retail_expansion.two_column.content_right'
          }
        />
        <ButtonWrapper>
          <Button.Link href="#whitepaper_form">
            {I18n.t(
              'pages.landing.retail_expansion.button.download_white_paper'
            )}
          </Button.Link>
        </ButtonWrapper>
      </Section.Wrapper>

      <DataSection
        image={dataImage}
        headline={'pages.landing.retail_expansion.data_section.headline'}
        content={'pages.landing.retail_expansion.data_section.content'}
        buttonText={
          'pages.landing.retail_expansion.button.download_white_paper'
        }
        buttonLink={'#whitepaper_form'}
        clipPathColor={'#ffffff'}
        backgroundColor={'#f4f5f8'}
      />

      <TripleInfoBox
        headline={'pages.landing.retail_expansion.triple_info_box.headline'}
        hasIcons={false}
        contentLeft={
          'pages.landing.retail_expansion.triple_info_box.content_left'
        }
        contentMid={
          'pages.landing.retail_expansion.triple_info_box.content_mid'
        }
        contentRight={
          'pages.landing.retail_expansion.triple_info_box.content_right'
        }
        backgroundColor={'#ffffff'}
      />

      <Testimonials />

      <Section.Wrapper>
        <HeadlineWrapper>
          <CustomHeadline style={{ marginTop: '6.25rem' }}>
            {I18n.t('pages.landing.retail_expansion.who_are_we.headline')}
          </CustomHeadline>
        </HeadlineWrapper>
        <TwoColumnHeadlineText
          headingLeft={I18n.t(
            'pages.landing.retail_expansion.who_are_we.two_column.headline_left'
          )}
          headingRight={I18n.t(
            'pages.landing.retail_expansion.who_are_we.two_column.headline_right'
          )}
          contentLeft={
            'pages.landing.retail_expansion.who_are_we.two_column.content_left'
          }
          contentRight={
            'pages.landing.retail_expansion.who_are_we.two_column.content_right'
          }
        />
        <Spacer size="3.25rem" transparent />
      </Section.Wrapper>

      <WhitepaperWrapper>
        <Section.Wrapper>
          <WhitePaperContact
            textPreSource="pages.landing.retail_expansion"
          />
        </Section.Wrapper>
      </WhitepaperWrapper>

      <Spacer size="10rem" mid />
      <Contact
        teaser="pages.landing.retail_expansion.contact.teaser"
        cta="pages.landing.retail_expansion.contact.cta"
        styleOverrides={{
          marginBottom: '0'
        }}
      />
    </div>
  )
}

const Top = styled.div`
  position: relative;
  aspect-ratio: 2.5;
  background-image: url(${backgroundImage});
  background-repeat: no-repeat;
  background-position: left center;
  background-size: cover;

  ${props => props.theme.breakpoints.phone} {
    display: none;
  }
`

const CustomHeadline = styled(Headline)`
  font-size: 2.5rem !important;
`

const HeadlineWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  margin: 3rem 0;
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 4.75rem;

  a {
    font-size: 1rem;
  }
`

const WhitepaperWrapper = styled.div`
  background-color: #f4f5f8;
`

export default LandingRetailExpansion
