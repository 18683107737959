import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import styled from '@emotion/styled'
import I18n, { Trans } from '../i18n'

function WhitepaperConfirmation({ headline, subline }) {
  useEffect(() => {
    if (window.location.search.length > 0) {
      const params = new URLSearchParams(window.location.search)
      const token = params.get('token')

      if (token) {
        // Call the confirmation API
        fetch(`/whitepaper/confirm?token=${token}`)
          .then(response => {
            if (!response.ok) {
              throw new Error('Failed to confirm the whitepaper request.')
            }
            return response.json()
          })
          .catch(error => {
            console.error('API call failed:', error)
          })
      }
    }
  }, [])

  return (
    <div style={{ backgroundColor: '#f4f5f8' }}>
      <Helmet>
        <title>{I18n.t('whitepaper_mailer.opt_in_page.title')}</title>
      </Helmet>
      <TextWrapper>
        <CustomHeadline>{ headline }</CustomHeadline>
        <p>{ subline }</p>
        <p>
          Falls Sie Fragen haben oder weitere Informationen benötigen, stehen
          wir Ihnen gerne zur Verfügung.
        </p>
        <p>
          Mit freundlichen Grüßen,
          <br />
          Ihr hystreet.com-Team
        </p>
      </TextWrapper>
    </div>
  )
}

const CustomHeadline = styled.div`
  font-size: 22px;
  letter-spacing: 0.44px;
  text-transform: uppercase;
  font-weight: 900;
  border-left: 4px solid #e73c57;
  padding-left: 0.75rem;
`

const TextWrapper = styled.div`
  max-width: 600px;
  margin: 4rem auto 6rem;
  padding: 2.5rem;
  line-height: 27px;
  color: #183e62;
  letter-spacing: 0.34px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  background-color: #fff;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
`

export default WhitepaperConfirmation
