/**
 * Common components used throughout the different variants of the
 * "LocationStatsCardItem".
 */
import PropTypes from 'prop-types'
import React from 'react'
import styled from '@emotion/styled'
import I18n from '../../../../i18n'

export const ItemContainer = styled.div(props => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: props.justifyContent || 'flex-start',
  alignItems: 'center',
  width: props.fullWidth ? '100%' : '',
  borderTop: props.hideBorder ? null : '3px solid #E5E5E5',
  paddingLeft: 12,
  paddingRight: 12,
  minHeight: props.minHeight || 90
}))

export const TimestampRow = styled.div(props => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '100%'
}))

export const TimestampLabel = styled.p(props => ({
  marginTop: 5,
  fontSize: 14,
  lineHeight: props.theme.fontSizes.l.lineHeight,
  color: '#979797',
  fontWeight: props.bold ? 'bold' : 'normal'
}))

export const PedestrianCountDifferenceInPercentagesLabel = styled.div(props => {
  return {
    borderRadius: 5,
    padding: '3px 10px',
    color: props.percentage >= 0 ? '#2B8923' : '#E73C58',
    backgroundColor: props.percentage >= 0 ? '#D5E7D3' : '#E73C5833',
    fontSize: props.theme.fontSizes.s.fontSize,
    lineHeight: props.theme.fontSizes.l.lineHeight,
    fontWeight: 900,
    whiteSpace: 'nowrap',
    minWidth: '5rem',
    textAlign: 'center'
  }
})

export const ComparisonRow = styled.div(props => ({
  display: 'flex',
  height: '100%',
  width: '100%',
  justifyContent: 'center'
}))

export const WalkingDirectionContainer = styled.div(props => {
  return {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    flex: 1,
    textAlign: props.rtl ? 'right' : 'left',
    marginTop: '0.8rem',
    maxWidth: '50%'
  }
})

export const AbsolutePedestrianCountLabel = styled.div(props => ({
  fontSize: props.small ? 10 : 14,
  lineHeight: '25px',
  color: props.color || props.theme.chroma.base.css()
}))

export const PercentageOfPedestriansWalkingInDirectionLabel = styled.p(
  props => {
    return {
      fontWeight: 900,
      fontSize: '3rem',
      lineHeight: '2.3rem',
      letterSpacing: 0.5,
      textTransform: 'uppercase',
      color: props.theme.chroma.base.css(),
      marginTop: '0.8rem',
      marginBottom: '0.8rem'
    }
  }
)

export const PercentageOfTotalPedestriansLabel = styled.p(props => {
  return {
    fontWeight: 900,
    color: props.color || props.theme.chroma.base.css(),
    fontSize: props.theme.fontSizes.xxl.fontSize,
    lineHeight: '2.3rem',
    letterSpacing: '0.5px',
    textTransform: 'uppercase'
  }
})

export const IncidentItem = ({ incident, icon }) => {
  return (
    <Container>
      <div>
        <Name>{incident.name}</Name>
        <Description  dangerouslySetInnerHTML={{ __html: incident.description }}></Description>
        <Timerange>
          {I18n.strftime(new Date(incident.activeFrom), '%d.%m.%Y')}-
          {I18n.strftime(new Date(incident.activeTo), '%d.%m.%Y')}
        </Timerange>
      </div>
    </Container>
  )
}

IncidentItem.propTypes = {
  anchorTo: PropTypes.object,
  onClose: PropTypes.func,
  icon: PropTypes.any,
  incident: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    icon: PropTypes.string,
    activeFrom: PropTypes.string,
    activeTo: PropTypes.string
  })
}

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`

const Name = styled.h4`
  margin-top: 0.75rem;
  margin-bottom: 0.25rem;
  line-height: 1.4rem;
`

const Description = styled.p`
  line-height: 1.2;
  padding-bottom: .5rem;

  a {
    text-decoration: underline;
    };
`

const Timerange = styled.p`
  line-height: 1.2;
`

export { ChildOrAdultStatsCardItem } from './ChildOrAdultStatsCardItem'
export { PedestrianCountComparisonStatsCardItem } from './PedestrianCountComparisonStatsCardItem'
export { PedestrianCountStatsCardItem } from './PedestrianCountStatsCardItem'
export { PercentagePerZoneStatsCardItem } from './PercentagePerZoneStatsCardItem'
export { WalkingDirectionComparisonStatsCardItem } from './WalkingDirectionComparisonStatsCardItem'
export { WalkingDirectionStatsCardItem } from './WalkingDirectionStatsCardItem'
