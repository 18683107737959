import I18n from "i18n-js"

const DatetimeHelpers = {
  zoneFromTimestamp: timestamp => {
    try {
      return `Etc/GMT-${parseInt(timestamp.match(/.*\+(\d{2}):(\d{2})/)[1])}`
    } catch (_e) {
      return 'UTC'
    }
  },

  getYearSelectorItems:(earliestMeasurementTimestamp, latestMeasurementTimestamp)  => {
    const earliestMeasurement = new Date(earliestMeasurementTimestamp)
    const earliestYear = earliestMeasurement.getFullYear()

    const latestMeasurement = new Date(latestMeasurementTimestamp)
    const latestYear = latestMeasurement.getFullYear()

    const INCLUDE_UPPER_BOUND_YEAR = latestMeasurement.getMonth() !== 0
    const yearRange = latestYear - earliestYear
    const numberOfAvailableYears = INCLUDE_UPPER_BOUND_YEAR ? yearRange + 1 : yearRange

    const selectorItems = []

    for (let i = 0; i < numberOfAvailableYears; i++) {
      const yearKeyAndLabel = earliestYear + i
      selectorItems.push({
        key: yearKeyAndLabel,
        label: yearKeyAndLabel.toString()
      })
    }

    return selectorItems.reverse()
  },

  getMonthSelectorItems: (
    earliestMeasurementTimestamp,
    latestMeasurementTimestamp,
    year
  ) => {
    const earliestMeasurement = new Date(earliestMeasurementTimestamp)
    const earliestMonth = earliestMeasurement.getMonth()
    const earliestYear = earliestMeasurement.getFullYear()
  
    const latestMeasurement = new Date(latestMeasurementTimestamp)
    const latestMonth = latestMeasurement.getMonth()
    const latestYear = latestMeasurement.getFullYear()

    // return an empty array if the request year is not within the range of the timestamps
    if (year < earliestYear || year > latestYear) {
      return []
    }
  
    const allMonths = Array.from({ length: 12 }, (e, i) => {
      const month = new Date(null, i + 1, null)
      const label = I18n.strftime(month, '%B')
      return { key: month.getMonth() + 1, label: label }
    })
  
    if (year === latestYear) {
      return allMonths.slice(0, latestMonth)
    }
  
    if (year === earliestYear) {
      return allMonths.slice(earliestMonth, undefined)
    }
  
    return allMonths
  },


}

export default DatetimeHelpers
