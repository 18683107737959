import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { Trans } from '../i18n'

import Section from '../components/Section'
import TextBox from '../components/TextBox'
import TextBoxIcon from '../components/TextBoxIcon'
import * as Icon from '../components/Icon'
import Headline from '../components/Headline'

function TripleInfoBox({
  headline,
  hasIcons,
  contentLeft,
  contentMid,
  contentRight,
  backgroundColor
}) {
  return (
    <Section mid css={{ padding: '8rem 0 5.5rem 0', backgroundColor: backgroundColor }}>
      <Section.Wrapper>
        <Headline breakout>
          <Trans id={headline} />
        </Headline>
        <TextBoxWrapper hasIcons={hasIcons}>
          <TextBox>
            {hasIcons && (
              <TextBoxIcon>
                <Icon.Login />
              </TextBoxIcon>
            )}
            <Text>
              <Trans id={contentLeft} copy />
            </Text>
          </TextBox>
          <TextBox>
            {hasIcons && (
              <TextBoxIcon>
                <Icon.Report />
              </TextBoxIcon>
            )}
            <Text>
              <Trans id={contentMid} copy />
            </Text>
          </TextBox>
          <TextBox>
            {hasIcons && (
              <TextBoxIcon>
                <Icon.Graph />
              </TextBoxIcon>
            )}
            <Text>
              <Trans id={contentRight} copy />
            </Text>
          </TextBox>
        </TextBoxWrapper>
      </Section.Wrapper>
    </Section>
  )
}

const TextBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  
  ${props =>
    !props.hasIcons &&
    `
    margin-top: 3.75rem;
`}

  ${props => props.theme.breakpoints.tabletUp} {
    flex-direction: row;
    justify-content: space-between;
  }

  @media (max-width: 767px) {
    padding: 2.5rem 0;
  }
`

const Text = styled.div`
  p {
    line-height: 1.5;

    a {
      white-space: nowrap;
    }

    a::before {
      content: '> ';
    }
  }

  p:last-of-type {
    margin-bottom: 1rem;
  }
`

TripleInfoBox.propTypes = {
  headline: PropTypes.string.isRequired,
  hasIcons: PropTypes.bool,
  contentLeft: PropTypes.string.isRequired,
  contentMid: PropTypes.string.isRequired,
  contentRight: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string
}

export default TripleInfoBox
