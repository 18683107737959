import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import I18n from '../../i18n'
import useLocation from '../../lib/use-location'
import Button from '../Button'
import Select from '../Select'
import { HorizontalSpacer } from '../Spacer'
import Modal from './Modal'
import DatetimeHelpers from '../../lib/helpers/datetime-helpers'

export default function ReportModal({ isOpen, onRequestClose }) {

  const { location } = useLocation()

  const yearSelectorItems = DatetimeHelpers.getYearSelectorItems(
    location.metadata.earliestMeasurementAt,
    location.metadata.latestMeasurementAt
  )

  if (yearSelectorItems.length === 0) return null

  const monthSelectorItems = DatetimeHelpers.getMonthSelectorItems(
    location.metadata.earliestMeasurementAt,
    location.metadata.latestMeasurementAt,
    yearSelectorItems[0].key
  )

  if (monthSelectorItems.length === 0) return null

  const [selectedYear, setSelectedYear] = useState(yearSelectorItems[0])
  const [availableMonths, setAvailableMonths] = useState(monthSelectorItems)
  const [selectedMonth, setSelectedMonth] = useState(null)

  // Recompute available months whenever the year changes.
  useEffect(() => {
    const availableMonths = DatetimeHelpers.getMonthSelectorItems(
      location.metadata.earliestMeasurementAt,
      location.metadata.latestMeasurementAt,
      selectedYear.key
    )
    setAvailableMonths(availableMonths)
  }, [
    location.metadata.earliestMeasurementAt,
    location.metadata.latestMeasurementAt,
    selectedYear
  ])

  // Set initial month as soon as available months are computed.
  useEffect(() => {
    if (!availableMonths) {
      return
    }

    if (!selectedMonth) {
      setSelectedMonth(availableMonths[availableMonths.length - 1])
    }
  }, [availableMonths, selectedMonth])

  // Recompute the selected month based on available months.
  useEffect(() => {
    if (!availableMonths || !selectedMonth) {
      return
    }

    const monthIsAvailableInSelectedYear =
      availableMonths.findIndex(month => {
        return month.key === selectedMonth.key
      }) !== -1

    let monthToSetAfterYearChanged = monthIsAvailableInSelectedYear
      ? selectedMonth
      : availableMonths[0]

    setSelectedMonth(monthToSetAfterYearChanged)
  }, [availableMonths, selectedMonth])

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      showCloseButton
      title={'location.reports.headline'}
    >
      <VerticalWrapper>
        <div>
          <ExplanatoryParagraph>
            {I18n.t('location.reports.explanatory_paragraph_1')}
          </ExplanatoryParagraph>

          <SelectWrapper>
            {availableMonths && (
              <StyledSelect
                onChange={item => setSelectedMonth(item)}
                items={availableMonths}
                baseTitle={
                  selectedMonth?.label ||
                  I18n.t('location.reports.select_month')
                }
              />
            )}

            <HorizontalSpacer width={'2rem'} transparent />
            <StyledSelect
              onChange={item => setSelectedYear(item)}
              items={yearSelectorItems}
              baseTitle={
                selectedYear?.label || I18n.t('location.reports.select_year')
              }
            />
          </SelectWrapper>

          <StyledButton
            onClick={() => {
              const targetUrl = new URL(
                `/locations/${location.id}/report/${selectedYear.key}/${selectedMonth.key}`,
                window.location.href
              )

              targetUrl.searchParams.append('locale', I18n.currentLocale())

              window.open(targetUrl, '_blank')
            }}
          >
            {I18n.t('location.reports.generate')}
          </StyledButton>
        </div>
      </VerticalWrapper>
    </Modal>
  )
}

ReportModal.propTypes = {
  onRequestClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool
}

const SelectWrapper = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
`

const StyledSelect = styled(Select)`
  flex: 1;
`

const StyledButton = styled(Button)`
  margin-top: 2rem;
  width: 100%;
`

const VerticalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  margin-top: 1rem;
  margin-bottom: 3rem;
  flex-shrink: 0;
  flex: 1 0 50%;

  > div {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
`

const ExplanatoryParagraph = styled.p`
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 25px;
  letter-spacing: -0.178544px;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  color: ${p => p.theme.chroma.base.css()};
`
