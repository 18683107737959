import React from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import I18n from '../i18n'

import Button from '../components/Button'

import Section from '../components/Section'
import { Trans } from '../i18n'

function DataSection({
  image,
  headline,
  content,
  buttonText,
  buttonLink,
  clipPathColor,
  backgroundColor
}) {
  return (
    <CustomSection clipPathColor={clipPathColor} row>
      <Section.Skew mid direction="down" position="top" inset />
      <HeaderWrapper base={50} image={image} />
      <Section.Half base={50}>
        <BackgroundWrapper backgroundColor={backgroundColor}>
          <TextWrapper hasButton={buttonText && buttonLink}>
            <Heading>
              <Trans id={headline} />
            </Heading>
            <Trans id={content} />
          </TextWrapper>
          {buttonText && buttonLink && (
            <ButtonWrapper>
              <Button.Link href={buttonLink}>{I18n.t(buttonText)}</Button.Link>
            </ButtonWrapper>
          )}
        </BackgroundWrapper>
      </Section.Half>
    </CustomSection>
  )
}
const HeaderWrapper = styled(Section.Half)`
  min-height: 23rem;

  @media (max-width: 500px) {
    min-height: 10rem;
  }

  @media (max-width: 960px) {
    min-height: 7rem;
  }
`

const CustomSection = styled(Section)`
  ${props =>
    props.clipPathColor &&
    `
    svg {
      fill: ${props.clipPathColor} !important;
    }
  `}

  @media (max-width: 960px) {
    svg {
      visibility: hidden !important;
    }
    flex-direction: column;
  }
`

const TextWrapper = styled.div`
  @media (max-width: 960px) {
    margin: 5rem 2rem ${props => (props.hasButton ? '1rem' : '6rem')} 2rem;
  }
  margin: 12rem 4rem ${props => (props.hasButton ? '1rem' : '6rem')} 4rem;
`

const BackgroundWrapper = styled.div`
  ${props =>
    props.backgroundColor &&
    `
    background-color: ${props.backgroundColor};
`}
`

const Heading = styled.h2`
  margin-bottom: 2rem;
  font-size: 1.5rem;
  font-weight: bold;
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  margin: 0 4rem 3rem 4rem;

  a {
    font-size: 1rem;
  }
`

DataSection.propTypes = {
  image: PropTypes.string.isRequired,
  headline: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  buttonText: PropTypes.string,
  buttonLink: PropTypes.string,
  clipPathColor: PropTypes.string,
  backgroundColor: PropTypes.string
}

export default DataSection
