import { useState } from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import I18n, { Trans } from '../i18n'

import Button from '../components/Button'
import Form from '../components/forms/Form'

function WhitePaperContact({ textPreSource }) {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    companyName: '',
    jobTitle: '',
    email: '',
    agreeToTerms: false,
    subscribeToNewsletter: false
  })

  const handleChange = event => {
    const { name, value, type, checked } = event.target
    setFormData(prevData => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value
    }))
  }

  return (
    <SectionWrapper id="whitepaper_form">
      <InfoSection>
        <Headline>
          {I18n.t(textPreSource + '.whitepaper.info.headline')}
        </Headline>
        <Text>
          <Trans id={textPreSource + '.whitepaper.info.content'} />
        </Text>
      </InfoSection>
      <FormSection>
        <Text>
          <Trans id={textPreSource + '.whitepaper.form.instructions'} />
        </Text>
        <CustomForm action="/whitepaper/initiation" method="post">
          <InputWrapper>
            <label htmlFor="firstName">
              <Trans id={textPreSource + '.whitepaper.form.firstName'} />
            </label>
            <input
              type="text"
              id="firstName"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
            />
          </InputWrapper>
          <InputWrapper>
            <label htmlFor="lastName">
              <Trans id={textPreSource + '.whitepaper.form.lastName'} />
            </label>
            <input
              type="text"
              id="lastName"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              required
            />
          </InputWrapper>
          <InputWrapper>
            <label htmlFor="companyName">
              <Trans id={textPreSource + '.whitepaper.form.companyName'} />
            </label>
            <input
              type="text"
              id="companyName"
              name="companyName"
              value={formData.companyName}
              onChange={handleChange}
              required
            />
          </InputWrapper>
          <InputWrapper>
            <label htmlFor="jobTitle">
              <Trans id={textPreSource + '.whitepaper.form.jobTitle'} />
            </label>
            <input
              type="text"
              id="jobTitle"
              name="jobTitle"
              value={formData.jobTitle}
              onChange={handleChange}
            />
          </InputWrapper>
          <InputWrapper style={{ width: '100%' }}>
            <label htmlFor="email">
              <Trans id={textPreSource + '.whitepaper.form.email'} />
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </InputWrapper>
          <CheckboxWrapper>
            <input
              type="checkbox"
              id="agreeToTerms"
              name="agreeToTerms"
              value="true"
              checked={formData.agreeToTerms}
              onChange={handleChange}
              required
            />
            <label htmlFor="agreeToTerms">
              <Trans id={textPreSource + '.whitepaper.form.terms'} />
            </label>
          </CheckboxWrapper>
          <CheckboxWrapper>
            <input
              type="checkbox"
              id="subscribeToNewsletter"
              name="subscribeToNewsletter"
              value="true"
              checked={formData.subscribeToNewsletter}
              onChange={handleChange}
            />
            <label htmlFor="subscribeToNewsletter">
              {I18n.t(textPreSource + '.whitepaper.form.newsletter')}
            </label>
          </CheckboxWrapper>
          <ButtonWrapper>
            <Button type="submit">
              {I18n.t(textPreSource + '.button.download_now')}
            </Button>
          </ButtonWrapper>
        </CustomForm>
      </FormSection>
    </SectionWrapper>
  )
}

const SectionWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 4rem 0;
  background-color: #f4f5f8;

  @media (max-width: 41rem) {
    flex-direction: column;
  }
`

const InfoSection = styled.div`
  width: 50%;
  padding: 2rem 1.5rem 2rem 0;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  @media (max-width: 41rem) {
    width: 100%;
  }
`

const FormSection = styled.div`
  width: 50%;
  padding: 2rem 1.5rem;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;

  @media (max-width: 41rem) {
    width: 100%;
  }
`

const Headline = styled.h2`
  font-size: 1.5rem;
  font-weight: 900;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: flex-end;
`

const Text = styled.div`
  font-size: 1rem;

  @media (max-width: 41rem) {
    margin-bottom: 2rem;
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  margin-top: 2rem;

  button {
    font-size: 1rem;

    @media (max-width: 1170px) {
      font-size: 0.75rem;
    }
  }
`

const InputWrapper = styled.div`
  display: flex;
  width: calc(50% - 1rem);
  flex-direction: column;
  gap: 0.5rem;

  @media (max-width: 1170px) {
    width: 100%;
  }

  input {
    background-color: #f4f5f8;
    height: 2.5rem;
    border-radius: 2px;
    border: solid 1px #dde2ea;
    padding: 0 0.5rem;
  }
`

const CheckboxWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
  align-items: center;
`

const CustomForm = styled(Form)`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 1rem 2rem;
  margin-top: 2rem;
`

WhitePaperContact.propTypes = {
  textPreSource: PropTypes.string.isRequired
}

export default WhitePaperContact
